import { useEffect } from "react";

const AdBanner = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.log("----");
      console.log(err);
    }
  }, []);

  return (
    <ins
      className='adsbygoogle'
      style={{
        display: "block",
      }}
      data-ad-client='ca-pub-6956767738713359'
      data-ad-slot='9406954214'
      data-ad-format='auto'
      data-full-width-responsive='true'
    ></ins>
  );
};

export default AdBanner;
