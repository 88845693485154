import Head from "next/head";
import styles from "@/styles/Home.module.css";
import { Spinner } from "@chakra-ui/react";
import HowToPlay from "./components/HowToPlay";
import Header from "./components/Header";
import DailtGuessContainer from "./components/GuessContainer";
import { useContext } from "react";
import { GlobalContext } from "@/state/GlobalContext";
import { DailyGameContextProvider } from "@/state/DailyGameContext";
import AdBanner from "./components/AdSense";

export default function Home() {
  const { isLoading } = useContext(GlobalContext);

  return (
    <>
      <Head>
        <title>Semantics</title>
        <meta name='description' content='An AI word game. Guess the secret word.' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='icon' href='/favicon.ico' />
        <style jsx global>{``}</style>
      </Head>
      <main className={styles.main}>
        {isLoading ? (
          <Spinner size={"xl"} />
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "flext-start",
                justifyContent: "flext-start",
                flex: 1,
                alignSelf: "center",
                maxWidth: 500,
                flexDirection: "column",
              }}
            >
              <DailyGameContextProvider>
                <Header />
                <DailtGuessContainer />
              </DailyGameContextProvider>

              <HowToPlay />
            </div>
          </div>
        )}
        <div
          id='ad-banner-3'
          style={{
            minHeight: 100,
            marginTop: 15,
            alignSelf: "stretch",
          }}
        ></div>
      </main>
    </>
  );
}
