import styles from "@/styles/Home.module.css";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { faBackward, faEllipsisVertical, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import MainMenu from "./MainMenu";

export default function Header() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginLeft: 25,
        marginRight: 25,
      }}
    >
      <a
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
        href='https://semantics.gg'
        rel='noopener noreferrer'
      >
        <Image src='/logo.png' alt='Semantics Logo' width={60} height={60} priority />
        <div className={styles.logo}>Semantics</div>
      </a>
      <div className='primary-text flex-center'>
        <MainMenu />
      </div>
    </div>
  );
}

const local_styles = {
  menuItem: {
    fontFamily: "Noto Sans",
    fontWeight: 500,
  },
};
