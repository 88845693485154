import { DailyGameContext, DailyGameContextProvider } from "@/state/DailyGameContext";
import { GlobalContext } from "@/state/GlobalContext";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { faBackward, faEllipsisVertical, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useMemo } from "react";

function MainMenu() {
  const { getHint, startNewGame } = useContext(DailyGameContext);
  const { numberOfCurrentDailyGames } = useContext(GlobalContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const games_arr = useMemo(() => {
    return new Array(numberOfCurrentDailyGames).fill(1);
  }, [numberOfCurrentDailyGames]);

  return (
    <div
      style={{
        marginLeft: 5,
      }}
    >
      <Menu isLazy>
        <MenuButton>
          <FontAwesomeIcon size='xl' icon={faEllipsisVertical} />
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              getHint();
            }}
            icon={<FontAwesomeIcon size='lg' icon={faLightbulb} />}
          >
            Hint
          </MenuItem>
          <MenuItem onClick={onOpen} icon={<FontAwesomeIcon size='lg' icon={faBackward} />}>
            Previous Games
          </MenuItem>
        </MenuList>
      </Menu>

      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          style={{
            marginRight: 25,
            marginLeft: 25,
          }}
        >
          <ModalHeader>Choose a game:</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            style={{
              paddingBottom: 35,
            }}
          >
            <div className='flex-column'>
              <Button
                onClick={() => {
                  const newGameId = Math.floor(Math.random() * numberOfCurrentDailyGames) + 1;
                  startNewGame(newGameId);
                  onClose()
                }}
              >
                Random
              </Button>

              {games_arr.map((g, i) => {
                const gameId = i + 1;
                return (
                  <Button
                    key={`game-${gameId}`}
                    className='tm-10'
                    onClick={() => {
                      startNewGame(gameId);
                      onClose()

                    }}
                  >
                    Game #{gameId}
                  </Button>
                );
              })}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default function MainMenuWrapper() {
  return <MainMenu />;
}

const local_styles = {
  menuItem: {
    fontFamily: "Noto Sans",
    fontWeight: 500,
  },
};
