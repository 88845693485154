import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function HowToPlay() {

    return <div  className='how-to-play' style={{
        
        // backgroundColor: '#e7f0ff',
        paddingLeft: 28,
        paddingRight: 28,
        paddingTop: 20,
        paddingBottom: 25,
        borderRadius: 20,
        marginTop: 30,
        marginLeft: 25,
        marginRight: 25,

        display: 'flex',
        flexDirection: 'column'
        // alignSelf: 'center',
    }}>
        <div  style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            // backgroundColor: '#e7f0ff',
            
            marginBottom: 10,
        }}>
            <FontAwesomeIcon size="lg" icon={faCircleQuestion}/>
            <div
                style={{
                    fontFamily: 'Noto Sans',
                    fontWeight: 700,
                    marginLeft: 10,
                    fontSize: 15,
                    // color: Colors[theme].text
                }}
            >How to play</div>
        </div>
        <div style={
            {
                fontSize: 14,
                fontFamily: 'Noto Sans',

            // fontFamily: Fonts.NotoSansRegular,
            // color: Colors[theme].text,
        }
        }>
            Find the secret word. You have unlimited guesses. {"\n"}<div></div>
            <div style={{
                height: 10
            }}></div>

            The AI sorted all the english words in order of how they are semantically similar.{'\n\n'}
            Each time you make a guess, you will get a score of how close that guess is to the secret word.

            A score of 1 means you have found the secret word.
        </div>
    </div>
}